import Image from 'next/image';
import Link from 'next/link';

import { BackButton, Layout, TopNavigation } from './NotFound.style';
import HomeImage from './ic-hall-24-FF9800.png';
import NotFoundImage from '@/assets/images/img-error-404.png';

const NotFound = () => {
  return (
    <Layout>
      <TopNavigation>
        <Link href="/">
          <Image src={HomeImage} alt="logo" />
        </Link>
      </TopNavigation>

      <Image
        className="image"
        src={NotFoundImage}
        alt="not found"
        width={284}
      />
      <h1 className="title">여긴 당신이 찾는 웹페이지가 아니에요</h1>
      <h2 className="sub-title">404 Page not found</h2>
      <BackButton onClick={() => history.back()}>이전 페이지 가기</BackButton>
    </Layout>
  );
};

export default NotFound;
